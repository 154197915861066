
import VueRouter from 'vue-router';

const routes = [
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:()=>import('../components/Index'),
        children:[
            {
                path:'/Home',
                name:'home',
                meta:{
                    title:'首页'
                },
                component:()=>import('../components/Home')
            },
            {
                path:'/Profile',
                name:'profile',
                meta:{
                    title:'个人中心'
                },
                component:()=>import('../components/Profile')
            },
            {
                path:'/Supplier',
                name:'Supplier',
                meta:{
                    title:'供应商'
                },
                component:()=>import('../components/Supplier')
            },
            {
                path:'/SkuManage',
                name:'SkuManage',
                meta:{
                    title:'供应商'
                },
                component:()=>import('../components/SkuManage')
            },
            {
                path:'/ProcurementManager',
                name:'ProcurementManager',
                meta:{
                    title:'采购管理'
                },
                component:()=>import('../components/ProcurementManager')
            },
            {
                path:'/ExcelTable',
                name:'ExcelTable',
                meta:{
                    title:'Excel表格'
                },
                component:()=>import('../components/ExcelTable')
            },
            {
                path:'/skuInventoryItems',
                name:'skuInventoryItems',
                meta:{
                    title:'库存详情'
                },
                component:()=>import('../components/skuInventory/skuInventoryItems')
            },
            {
                path:'/skuStorehouseManager',
                name:'skuStorehouseManager',
                meta:{
                    title:'仓库管理'
                },
                component:()=>import('../components/skuInventory/skuStorehouseManager')
            },
            {
                path:'/skuInventoryHistory',
                name:'skuInventoryHistory',
                meta:{
                    title:'库存操作记录'
                },
                component:()=>import('../components/skuInventory/skuInventoryHistory')
            },
            {
                path:'/purchaseWaitBeStorage',
                name:'purchaseWaitBeStorage',
                meta:{
                    title:'入库订单处理'
                },
                component:()=>import('../components/skuInventory/purchaseWaitBeStorage')
            },
            {
                path:'/Customer',
                name:'Customer',
                meta:{
                    title:'客户管理'
                },
                component:()=>import('../components/salesManagement/Customer')
            },
            {
                path:'/SalesDetailsManage',
                name:'SalesDetailsManage',
                meta:{
                    title:'订单详情'
                },
                component:()=>import('../components/salesManagement/SalesDetailsManage')
            },
            {
                path:'/SalesSummaryManage',
                name:'SalesSummaryManage',
                meta:{
                    title:'订单详情'
                },
                component:()=>import('../components/salesManagement/SalesSummaryManage')
            },
            {
                path:'/SellingPriceManage',
                name:'SellingPriceManage',
                meta:{
                    title:'销售价格管理'
                },
                component:()=>import('../components/salesManagement/SellingPriceManage')
            },
        ]
    }
]

const router = new VueRouter({
    mode:'history',
    routes
})

export function resetRouter() {
    router.matcher = new VueRouter({
        mode:'history',
        routes: []
    }).matcher
}
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export  default router;